import { QuestionAccordion } from 'apps/bridge/src/components/Faq/QuestionAccordion';

export function FaqSidebar() {
  return (
    <div className="flex w-full flex-col p-4 pl-6 font-sans lg:w-[420px] lg:border-l lg:border-sidebar-border">
      <span className="mt-6 mb-8 font-mono text-base font-medium uppercase text-stone-400">
        Frequently asked questions
      </span>
      <QuestionAccordion
        question="Can I still use the legacy bridge?"
        answer="Yes, but the legacy bridge will no longer be updated in the future. However this doesn't mean that the app wouldn't be maintained."
      />
      <QuestionAccordion
        question="I used the lagacy bridge in the past, how do I find my deposit or withdrawal?"
        answer="Any of the bridges including the legacy bridge would have information on all your past bridges."
      />
      <QuestionAccordion
        question="Why will the legacy bridge no longer be updated in the future?"
        answer="Base is committed to decentralization and the Superchain vision. Leveraging the community to bootstrap the Superchain bridges is a step in that direction; increasing censorship resistance and decentralization."
      />
      <QuestionAccordion
        question="Who operates Brid.gg?"
        answer=' Brid.gg is operated by third parties, not by Coinbase Technologies, Inc. ("Coinbase"). Coinbase does not control, operate, or assume any responsibility for the performance of these external platforms. Before using  Brid.gg, you may be required to agree to their terms and conditions. We strongly recommend you review these terms carefully, as well as any privacy policies, to understand your rights and obligations. The integration or inclusion of  Brid.gg does not imply an endorsement or recommendation of any bridge by Coinbase.'
      />
      <QuestionAccordion
        question="What if I have a question, issue or problem?"
        answer={
          <>
            The{' '}
            <a href="https://base.org/discord" className="underline">
              Base Discord
            </a>{' '}
            community is available around the clock for general questions, assistance and support!
            You can create a support ticket in the #general-support channel.
          </>
        }
      />
    </div>
  );
}
