import { FaqSidebar } from 'apps/bridge/src/components/Faq/FaqSidebar';



import dynamic from 'next/dynamic';


function Disclaimer() {
  return (
    <p className="py-6 text-muted lg:pr-16">
      Coinbase Technologies, Inc., provides links to these independent service providers for your
      convenience but assumes no responsibility for their operations. Any interactions with these
      providers are solely between you and the provider with the exception of our own legacy bridge.
    </p>
  );
}

// This helps us avoid hydration errors as we randomize the order of cards
const BridgeCardsDynamic = dynamic(async () => import('./BridgeCards'), { ssr: false });

function Content() {
  return (
    <div className="text-white bg-gray-900">
      <div className="container px-4 py-8 mx-auto lg:pr-16">
        <h1 className="mb-8 text-6xl">Official Bridges</h1>
        <p className="mb-16 text-2xl lg:max-w-[33em]">
          Go to the Legacy Bridge or Brid.gg to bridge your assets to and from Base. For questions, see
          our FAQ.
        </p>

        <BridgeCardsDynamic />
      </div>

      <div className="hidden w-full pt-4 border-t border-sidebar-border lg:inline-block" />
      <div className="container px-4 mx-auto">
        <div className="hidden lg:inline-block">
          <Disclaimer />
        </div>
      </div>
    </div>
  );
}

export default Content;

export function DeprecationContent() {
  return (
    <div className="flex flex-col items-stretch border-t border-sidebar-border lg:flex-row">
      <div className="flex flex-col grow">
        <Content />
      </div>
      <FaqSidebar />
      <div className="p-4 lg:hidden">
        <Disclaimer />
      </div>
    </div>
  );
}
